'use client';

import { Button } from '@nextui-org/button';
import * as React from 'react';
import Link from 'next/link';

import { ArrowLeftSvg, ArrowRightSvg } from '@/assets/icons';
import { Carousel, SectionTitle } from '@/components';
import { Article } from '@/models/article';
import { CarouselRef } from '@/components/Carousel/Carousel';

interface MagazineSectionProps {
  articles: Article[];
}

const MagazineSection: React.FC<MagazineSectionProps> = ({ articles }) => {
  const carouselRef = React.useRef<CarouselRef | null>(null);

  if (!articles.length) return null;

  const handleNavigateCarousel = (direction: 'prev' | 'next') => {
    if (!carouselRef.current) return;

    if (direction === 'prev') {
      carouselRef.current.prev();
    } else {
      carouselRef.current.next();
    }
  };

  return (
    <section className='flex flex-col gap-10 py-14'>
      <div className='container flex flex-row items-center justify-between gap-4'>
        <SectionTitle title='E-Magazine' />

        <div className='flex flex-row gap-1'>
          <Button
            isIconOnly
            aria-label='Previous magazine'
            className='border-neutral-black hover:bg-neutral-black hover:text-neutral-white hover:!opacity-100'
            endContent={<ArrowLeftSvg className='w-5' />}
            radius='none'
            variant='bordered'
            onClick={() => handleNavigateCarousel('prev')}
          />
          <Button
            isIconOnly
            aria-label='Next magazine'
            className='border-neutral-black hover:bg-neutral-black hover:text-neutral-white hover:!opacity-100'
            endContent={<ArrowRightSvg className='w-5' />}
            radius='none'
            variant='bordered'
            onClick={() => handleNavigateCarousel('next')}
          />
        </div>
      </div>

      <Carousel ref={carouselRef} articles={articles} />

      <Link className='self-center' href='/e-magazines'>
        <Button
          className='border-neutral-black hover:bg-neutral-black hover:text-neutral-white hover:!opacity-100'
          endContent={<ArrowRightSvg className='w-5' />}
          radius='none'
          size='lg'
          variant='bordered'
        >
          See all E-Magazines
        </Button>
      </Link>
    </section>
  );
};

export default MagazineSection;
