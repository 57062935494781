'use client';

import { Button } from '@nextui-org/button';
import { cn } from '@nextui-org/theme';
import { motion } from 'framer-motion';
import * as React from 'react';
import Image from 'next/image';

import { ArrowRightSvg } from '@/assets/icons';
import { BmwThreeStrikesSvg } from '@/assets/svgs';
import { FallbackImage } from '@/components';
import { body, heading } from '@/theme/typography';

const ModelsSection: React.FC = () => {
  return (
    <section className='relative flex h-[880px] flex-col items-center overflow-hidden bg-blue-950 text-neutral-white'>
      <div className='container z-10 flex flex-col items-center gap-8 p-12 text-center'>
        <hgroup className='flex max-w-4xl flex-col items-center gap-4'>
          <BmwThreeStrikesSvg width={36} />
          <h2 className={heading({ size: 'display' })}>BMW Models</h2>
          <p className={body()}>
            Packed with the latest technology and design, this latest electric car model shows
            Carsova&apos;s dedication to providing environmentally friendly and high-performance
            vehicles.
          </p>
        </hgroup>

        <Button
          className='border-neutral-black bg-neutral-white'
          endContent={<ArrowRightSvg className='w-6' />}
          radius='none'
          size='lg'
          variant='solid'
        >
          See all BMW Models
        </Button>
      </div>

      <div className='absolute top-0 flex h-full w-full items-center justify-center overflow-hidden'>
        <motion.div
          className='h-full w-full overflow-hidden'
          initial={{ opacity: 0 }}
          transition={{ delay: 0.2, type: 'tween', duration: 1 }}
          viewport={{ once: false }}
          whileInView={{ opacity: 1 }}
        >
          <Image
            alt='BMW Models'
            className='h-full w-full object-cover'
            height={901}
            src='https://storage.googleapis.com/upload-xdata/xdata-file/undefined/data-crawled/a03d3620-cea8-46fb-b039-d92c82ef9ece/as.svg'
            width={1038}
          />
        </motion.div>
      </div>

      <div
        className={cn('absolute bottom-0 z-10 h-[427px] w-full', 'bg-gradient-to-b from-blue-950')}
      />
      <div
        className={cn(
          'absolute bottom-0 z-0 h-[427px] w-full',
          'bg-gradient-to-r from-bmw-red-500 via-bmw-blue-500 to-bmw-blue-300 mix-blend-hard-light',
        )}
      />
      <div className='absolute left-0 top-0 h-[300px] w-full bg-gradient-to-b from-blue-950 from-40%' />

      <div className='absolute bottom-2 left-1/2 z-10 aspect-[8/6] h-[427px] -translate-x-1/2 select-none object-contain md:h-[500px]'>
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          transition={{ delay: 0.5, type: 'tween' }}
          viewport={{ once: false }}
          whileInView={{ opacity: 1, y: 0 }}
        >
          <FallbackImage
            alt='BMW Models'
            className='h-full'
            height={560}
            loading='lazy'
            src='https://storage.googleapis.com/upload-xdata/xdata-file/undefined/data-crawled/b5336d21-a0da-412f-b673-67c09858cbd8/BMW.png'
            width={750}
          />
        </motion.div>
      </div>
    </section>
  );
};

export default ModelsSection;
